import { SessionStorageKeys } from '../../lib/constants';
import { WindowManagerAction, FilterActions, ViewConfigurationAction } from '../actionTypes';

const getActiveId = (state) => state.viewManagerReducer?.viewOrder[0] || 0;

/**
 * @description Store the window manager store in the session storage
 * @param {object} state
 * @param {object} action
 */
const storeWindowManagerStore = (state, action) => {
  if (Object.keys(WindowManagerAction).some((wa) => WindowManagerAction[wa] === action.type)) {
    sessionStorage.setItem('windowManagerStore', JSON.stringify(state.viewManagerReducer));
  }
};

/**
 * @description Store the filter store in the session storage
 * @param {object} state
 * @param {object} action
 * @param {number} id
 */
const storeFilter = (state, action, id) => {
  if (action.type === FilterActions.UPDATE_FILTER) {
    const currentSessionFilter = JSON.parse(sessionStorage.getItem(SessionStorageKeys.FILTER)) || {};
    const currentWindowData = state.viewWindowData.find((window) => window.id === id);
    if (currentWindowData) {
      currentSessionFilter[id] = currentWindowData.filterReducer;
      sessionStorage.setItem(SessionStorageKeys.FILTER, JSON.stringify(currentSessionFilter));
    }
  } else if (action.type === FilterActions.CLEAR_FILTER) {
    const currentSessionFilter = JSON.parse(sessionStorage.getItem(SessionStorageKeys.FILTER)) || {};
    if (currentSessionFilter?.[id]) {
      delete currentSessionFilter[id];
      sessionStorage.setItem(SessionStorageKeys.FILTER, JSON.stringify(currentSessionFilter));
    }
  } else if (action.type === WindowManagerAction.REMOVE_VIEW_WINDOW) {
    const currentSessionFilter = JSON.parse(sessionStorage.getItem(SessionStorageKeys.FILTER)) || {};
    if (currentSessionFilter?.[action.id]) {
      delete currentSessionFilter[action.id];
      sessionStorage.setItem(SessionStorageKeys.FILTER, JSON.stringify(currentSessionFilter));
    }
  }
};

const storeViewConfiguration = (state, action, id) => {
  if (action.type === ViewConfigurationAction.UPDATE_VIEWCONFIGURATION) {
    const currentViewConfiguration = JSON.parse(sessionStorage.getItem(SessionStorageKeys.VIEW_CONFIGURATION)) || {};
    const currentWindowData = state.viewWindowData.find((window) => window.id === id);
    if (currentWindowData) {
      currentViewConfiguration[id] = currentWindowData.viewConfigurationReducer;
      sessionStorage.setItem(SessionStorageKeys.VIEW_CONFIGURATION, JSON.stringify(currentViewConfiguration));
    }
  } else if (action.type === WindowManagerAction.REMOVE_VIEW_WINDOW) {
    const currentViewConfiguration = JSON.parse(sessionStorage.getItem(SessionStorageKeys.VIEW_CONFIGURATION)) || {};
    if (currentViewConfiguration?.[action.id]) {
      delete currentViewConfiguration[action.id];
      sessionStorage.setItem(SessionStorageKeys.VIEW_CONFIGURATION, JSON.stringify(currentViewConfiguration));
    }
  }
};

/**
 * @description Middleware to persist the store in session storage
 * @param {object} store
 * @param {function} next
 * @param {object} action
 * @returns
 */
export const storeSession = (store) => (next) => (action) => {
  let result = next(action);
  const state = store.getState();
  const activeId = getActiveId(state);
  storeWindowManagerStore(state, action);
  storeFilter(state, action, activeId);
  storeViewConfiguration(state, action, activeId);
  return result;
};
