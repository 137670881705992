import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ViewWindow from './ViewWindow/ViewWindow';
import OperationsView from '../../OperationsView/OperationsView';
import { Treatment, ViewWindowType } from '../../../../lib/constants';
import { useSwapModeStore } from '../../../../hooks/useSwapModeStore/useSwapModeStore';
import {
  useWindowManagerStore,
  useWindowManagerDispatch,
} from '../../../../hooks/useWindowManagerStore/useWindowManagerStore';
import { ViewIdProvider } from '../../../../contexts/ViewIdContext/ViewIdContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext/FeatureFlagContext';
import './MultipleViewsContainer.css';

const getViewType = (viewWindows, isSwapModeActive) => {
  if (isSwapModeActive) {
    return ViewWindowType.SWAP_MODE;
  } else if (viewWindows?.length > 1) {
    return ViewWindowType.MULTI;
  }
  return ViewWindowType.SINGLE;
};

function MultipleViewsContainer({ currentActiveId, handleSortClick, sort, searchFilterTitles, setSearchFilterTitles }) {
  const { isSwapModeActive } = useSwapModeStore();
  const windowManagerStore = useWindowManagerStore();
  const { viewWindows, viewOrder, draggedMinimizedViews, maximizedViews, minimizedViews } = windowManagerStore;
  const { setActiveView, removeViewWindow, removeMinimizedView } = useWindowManagerDispatch();
  const { showFeature } = useFeatureFlag();
  const isTailIndicatorsEnabled = showFeature(Treatment.TAIL_NUMBER_INDICATORS);

  let viewType = useMemo(() => getViewType(viewWindows, isSwapModeActive), [viewWindows, isSwapModeActive]);

  const handleFocusToWindow = useCallback(
    (id) => {
      setActiveView(id);
    },
    [setActiveView],
  );

  const setSearchFilterTitleById = useCallback(
    (id, title) => {
      setSearchFilterTitles({
        ...searchFilterTitles,
        [id]: title,
      });
    },
    [searchFilterTitles, setSearchFilterTitles],
  );

  const handleCloseWindow = useCallback(
    (id) => {
      removeViewWindow(id);
    },
    [removeViewWindow],
  );

  const windows = useMemo(
    () =>
      viewWindows?.map((view) => {
        const { id, viewType: viewMode, defaultTitle, viewTitle, ...viewData } = view;
        if (viewType === ViewWindowType.SINGLE && minimizedViews.includes(id)) {
          removeMinimizedView(id);
        }
        const isMinimizedNotDragged = minimizedViews?.includes(id);
        const isMinimizedDragged = draggedMinimizedViews?.includes(id);
        const isMinimized = isMinimizedNotDragged || isMinimizedDragged;
        return (
          <ViewIdProvider key={`${id}-${viewType}`} id={id}>
            <ViewWindow
              id={id}
              variant={viewType}
              onFocusToWindow={() => handleFocusToWindow(id)}
              onClose={() => handleCloseWindow(id)}
              windowPosition={viewOrder.indexOf(id)}
              isFocused={id === viewOrder[0]}
              isMinimized={isMinimized}
              isMaximized={maximizedViews?.includes(id)}
              minimizedIndex={isMinimizedDragged ? null : minimizedViews?.indexOf(id)}
              isSwapModeActive={isSwapModeActive}
              currentActiveId={currentActiveId}
              title={viewTitle}
              isTailIndicatorsEnabled={isTailIndicatorsEnabled}
              isDefaultTitle={defaultTitle}
              searchFilterTitle={searchFilterTitles?.[id]}
              setSearchFilterTitle={(title) => setSearchFilterTitleById(id, title)}
              {...viewData}
            >
              {!isMinimized ? (
                <OperationsView
                  isSwapModeActive={false}
                  viewMode={viewMode}
                  handleSortClick={handleSortClick}
                  sort={sort ? sort[id] : null}
                />
              ) : (
                <></>
              )}
            </ViewWindow>
          </ViewIdProvider>
        );
      }),
    [
      viewWindows,
      viewOrder,
      draggedMinimizedViews,
      minimizedViews,
      maximizedViews,
      isSwapModeActive,
      currentActiveId,
      sort,
      handleSortClick,
      isTailIndicatorsEnabled,
      searchFilterTitles,
    ],
  );

  const swapModeWindow = useMemo(
    () => (
      <ViewIdProvider key={`${currentActiveId}-${viewType}`} id={currentActiveId}>
        <ViewWindow
          variant={ViewWindowType.SWAP_MODE}
          onFocusToWindow={() => handleFocusToWindow(currentActiveId)}
          onClose={() => handleCloseWindow(currentActiveId)}
          windowPosition={viewWindows?.find((view) => view.id === currentActiveId)?.windowPosition}
          isFocused={true}
          isTailIndicatorsEnabled={isTailIndicatorsEnabled}
        >
          <OperationsView
            isSwapModeActive={true}
            viewMode={null}
            handleSortClick={handleSortClick}
            sort={sort ? sort[currentActiveId] : null}
          />
        </ViewWindow>
      </ViewIdProvider>
    ),
    [
      currentActiveId,
      viewWindows,
      handleFocusToWindow,
      handleCloseWindow,
      sort,
      handleSortClick,
      isTailIndicatorsEnabled,
    ],
  );

  return (
    <div data-cy="multiple-views-container" className="multiple-views-container">
      {isSwapModeActive ? swapModeWindow : windows}
    </div>
  );
}

MultipleViewsContainer.propTypes = {
  handleSortClick: PropTypes.func,
  sort: PropTypes.object,
  currentActiveId: PropTypes.number,
  searchFilterTitles: PropTypes.object,
  setSearchFilterTitles: PropTypes.func,
};

export default MultipleViewsContainer;
